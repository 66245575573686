import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import ThemeColors from "../components/ThemeColors.js";

const ToggleButton = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        background: ThemeColors.grayishBlue2,
        opacity: 1,
        "&:before, &:after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 16,
            height: 16
        },
        "&:before": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(ThemeColors.grayishBlue)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
            opacity: 0.42
        },
        "&:after": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(ThemeColors.darkBlue)}" d="M19,13H5V11H19V13Z"/></svg>')`,
            right: 12,
            opacity: 0.42
        }
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 16,
        height: 16,
        margin: 2
    },
    "& .Mui-checked + .MuiSwitch-track": {
        opacity: "1 !important"
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: ThemeColors.darkBlue,
        "& + .MuiSwitch-track": {
            background: ThemeColors.darkBlue2
        }
    },
    "& :hover": {
        backgroundColor: "transparent !important"
    },
    "& .Mui-checked:hover": {
        backgroundColor: "transparent !important"
    }
}));

export default ToggleButton;